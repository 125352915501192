<template>
<Page id="administrator_users" title="Användare">
    <Loader v-if="usersloading" loadingtext="Hämtar användare..."></Loader>
    <div v-else>
        <b-table sort-by="username" :sort-desc="false" hover :items="users" :fields="headerfields">
            <template v-slot:cell(status)="data">
                <div class="p-1 text-center" :class="GetStatusClass(data.item.status)">{{GetStatusText(data.item.status)}}</div>
            </template>
        </b-table>
    </div>
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");

import userenum from "@/helpers/enums/user";

export default {
    name: "Users",
    components: {
        Page,
        Alert,
        Loader
    },
    data() {
        return {
            headerfields: [{
                    key: 'id',
                    label: "Id",
                    sortable: true
                },
                {
                    key: 'username',
                    label: "Användarnamn",
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'E-post',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Förnamn',
                    sortable: true
                },
                {
                    key: 'lastname',
                    label: 'Efternamn',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                }
            ]
        };
    },
    metaInfo: {
        title: "Användare",
        meta: [{
            name: "description",
            content: "Valbara turneringar",
        }, ],
    },
    computed: {
        usersloading() {
            return this.$store.getters["administratorget/usersloading"];
        },
        users() {
            var items = this.$store.getters["administratorget/users"];
            if (!items)
                return false;
            return items;
        },
    },
    methods: {
        svg(path) {
            return this.$images.svg(path);
        },
        GetStatusClass(status){
            var variant = userenum.getStatus(status).variant;
            return "bg-"+variant+" text-white";
        },
        GetStatusText(status){
            return userenum.getStatus(status).text;
        }

    },
    mounted() {
        this.$store.dispatch("administratorget/getUsers");
    },
};
</script>
