export default {
      status: {            
            0: {text:"Ansökt", variant:"info"},
            1: {text:"Epost-verifierad", variant:"warning"},
            2: {text:"Godkänd", variant:"success"},
            3: {text:"Avstängd", variant:"danger"}
      },
      getStatus(status){
            if(this.status[status])
                  return this.status[status];
            return {text: "OKÄND", variant: "dark"};
      }
};